<template>
    <div id="homeBox">
        <img src="../assets/img/logo.png" class="logo">
        <div class="title">
            <div>雷锋纪念馆数字藏品系统</div>
        </div>
        <div class="menuBox flex_w" v-if="list.length > 0">
            <div :class="['item', (index + 1) % 3 == 1 ? 'flex_b_c' : 'item2',`backColor${item.backColor}` ]"
                 v-for="(item, index) in list"
                 :key="index" @click="handlerMenu(item)">
                <!--                <img class="itemBg" :src="item.imgUrl">-->
                <div class="imgBox flex_c_c" :class="item.icon"></div>
                <div class="name">{{ item.name }}</div>
            </div>
        </div>
        <div class="title hint" v-else>暂无菜单权限哦，快去联系管理员分配菜单权限吧~</div>
        <div class="outLoginBtn" @click="logoutHandle">退出登录</div>
        <!--        <div class="version">版本：{{version}}</div>-->
        <PersonalCenter ref="personalCenter" @showPersonal="showPersonal"/>
    </div>
</template>

<script>
    import PersonalCenter from './layout/PersonalCenter'

    export default {
        components: {
            PersonalCenter
        },
        data() {
            return {
                version: '',
                list: JSON.parse(sessionStorage.getItem("allMenuList")) || [],
            }
        },
        mounted() {
            this.getUserPasswordChangeReminder()
            this.getVersion()
            //只要前六个
            this.list = this.list.splice(0,6)
            this.list.forEach((item, index) => {
                // if (index < 10) {
                //     this.$set(item, "imgUrl", require('../assets/img/menu' + index + '.png'))
                // } else if (index >= 10 && index < 20) {
                //     this.$set(item, "imgUrl", require('../assets/img/menu' + (index - 10) + '.png'))
                // } else if (index >= 20 && index < 30) {
                //     this.$set(item, "imgUrl", require('../assets/img/menu' + (index - 20) + '.png'))
                // } else {
                //     this.$set(item, "imgUrl", require('../assets/img/menu0.png'))
                // }
                this.$set(item, "backColor", index % 6)
            })
        },
        methods: {
            // 获取密码修改提示时间
            getUserPasswordChangeReminder() {
                let updatePasswordEnable = JSON.parse(sessionStorage.getItem('userInfo')).updatePasswordEnable
                let updatePasswordFlag = sessionStorage.getItem('updatePasswordFlag')
                if(updatePasswordEnable == '1' && updatePasswordFlag != '1') {
                    this.$axios(this.api.auth.getUserPasswordChangeReminder).then((res) => {
                        if (res.status) {
                            sessionStorage.setItem('updatePasswordFlag', '1')
                            this.$confirm(`您已${res.data.configValue}个月未更新密码，为了账号安全，建议您及时更改密码。`, '风险提示', {
                                confirmButtonText: '现在修改',
                                cancelButtonText: '下次修改',
                                type: 'warning'
                            }).then(() => {
                                this.$refs.personalCenter.showPersonal(true, '1');
                            })
                        }
                    })
                }
            },
            showPersonal(flag) {
                this.$refs.personalCenter.showPersonal(flag);
            },
            getVersion() {
                this.$axios(this.api.auth.getversion).then(data => {
                    if (data.status) {
                        this.version = data.data
                    }
                })
            },
            // 通知、待办
            toManagement() {
                this.$router.push({path: '/daily/management'})
            },
            // 点击菜单
            handlerMenu(menu) {
                if (menu.permission == "link") {
                    window.open(menu.href);
                    return
                }
                this.recursion(menu)
            },
            // 递归
            recursion(menu) {
                if (menu.href) {
                    this.$router.push({path: menu.href})
                } else {
                    if (menu.children.length) {
                        this.recursion(menu.children[0])
                    } else {
                        this.$message.error('该菜单暂未配置链接');
                    }
                }
            },
            // 退出
            logoutHandle() {
                this.$confirm(`确定进行[退出]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.clear();
                    this.$router.replace({name: 'login'});
                    window.location.reload();
                    this.$axios(this.api.auth.loginout, {}, 'post').then(data => {

                    })
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .version {
        font-size: 14px;
        position: fixed;
        bottom: 15px;
        right: 50px;
    }

    #homeBox {
        background: url('../assets/img/homeBg.png') no-repeat;
        background-size: 100% 100%;
        height: 100vh;
        overflow-y: auto;
        position: relative;

        .logo {
            width: 7rem;
            margin: 0.75rem 0 0 2.31rem;
        }

        .manageBtnBox {
            position: absolute;
            right: 30px;
            top: 50px;
        }

        .manageBtn {
            position: relative;
            width: 98px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            background: #E10A1E;
            border-radius: 2px;
            font-size: 12px;
            color: #FFFFFF;
            margin-bottom: 20px;
            cursor: pointer;
        }

        .tonghzia {
            position: relative;
            width: 98px;
            height: 28px;
            margin-bottom: 5px;
        }

        .backlogBtn {
            background: #FFA000;
        }

        .informIcon {
            width: 12px;
            height: 12px;
            margin-right: 7px;
        }

        .dot {
            padding: 0 3px;
            height: 16px;
            line-height: 15px;
            background: #E10A1E;
            border: 1px solid #FFFFFF;
            border-radius: 10px;
            font-size: 7px;
            color: #FFFFFF;
            position: absolute;
            right: -8px;
            top: -8px;
        }

        .title {
            text-align: center;
            font-size: 2.8rem;
            color: #FFFFFF;
            line-height: 2.75rem;
            padding: 0.69rem 0 5.44rem;
        }

        .hint {
            padding-top: 120px;
        }

        .menuBox {
            width: 54%;
            margin: 0 auto 20px;
            font-size: 0.75rem;
            color: #FFFFFF;
        }

        .menuBox {
            .item {
                width: 40%;
                height: 11.5rem;
                padding: 0 3rem 0 3rem;
                margin: 0 1rem 1rem 0;
                cursor: pointer;
                position: relative;
                border-radius: 0.5rem;
                z-index: 1;
            }

            .item2 {
                width: 26%;

                .imgBox {
                    position: absolute;
                    top: 2rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 4.4rem;
                    height: 4.4rem;
                    background: rgba(225, 225, 225, 0.1);
                    border-radius: 1rem;
                    font-size: 2.7rem;
                    font-weight: bold;
                    z-index: 1;
                    display: flex !important;
                }

                .name {
                    position: absolute;
                    bottom: 2rem;
                    left: 50%;
                    width: 100%;
                    text-align: center;
                    transform: translateX(-50%);
                    font-size: 1.25rem;
                }
            }

            .itemBg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            .name {
                flex: 1;
                text-align: center;
                z-index: 1;
                font-weight: 500;
                font-size: 1.55rem;
                color: #FFFFFF;
            }
        }

        .imgBox {
            width: 5rem;
            height: 5rem;
            background: rgba(225, 225, 225, 0.1);
            border-radius: 1rem;
            font-size: 3.1rem;
            font-weight: bold;
            z-index: 1;
            display: flex !important;
        }

        .outLoginBtn {
            width: 7.8rem;
            height: 3rem;
            line-height: 2.7rem;
            border-radius: 0px 1rem 0px 1rem;
            border: .15rem solid #FFFFFF;
            text-align: center;
            font-size: 0.95rem;
            color: #FFFFFF;
            position: fixed;

            bottom: 3.75rem;
            right: 3.75rem;
            cursor: pointer;
        }
    }

    .backColor0 {
        background: linear-gradient(90deg, #3567D6, #52A0F9);
    }

    .backColor1 {
        background: linear-gradient(0deg, #6E3EB7, #A51AB1);
    }

    .backColor2 {
        background: linear-gradient(0deg, #C32D47, #F84A4A);
    }

    .backColor3 {
        background: linear-gradient(90deg, #0684B1, #0DADC9);
    }

    .backColor4 {
        background: linear-gradient(0deg, #BC213C, #EB3C3C);
    }

    .backColor5 {
        background: linear-gradient(0deg, #E25527, #F58846);
    }

</style>
